const appConfigs = {
  billingItems: {
    highSheets: {
      id: 7,
      groupId: 6,
    },
    garageHighSheets: {
      id: 15,
      groupId: 5,
    }
  }
};
export const appConfig = appConfigs;
// export default {appConfig};
