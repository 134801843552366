import React from 'react';

const PageNotFound = () => {

  return (
    <>
        <h1>Oops! 404 page not found.</h1>
    </>
  );
};

export default PageNotFound;
