const prod = {
  url: {
    API_URL: 'https://convextest.com/api',
    API_URL_USERS: 'https://convextest.com/api/users',
    PAGE_SIZE: 20
  }
};
const dev = {
  url: {
    API_URL: 'http://127.0.0.1:8000',
    PAGE_SIZE: 20
  }
};
export const configs = process.env.NODE_ENV === 'development' ? dev : prod;