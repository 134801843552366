// Auth
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Articles
export const ARTICLES_LOADING = 'ARTICLES_LOADING';
export const GET_ARTICLES = 'GET_ARTICLES';
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';

// Users
export const USERS_LOADING = 'USERS_LOADING';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_BY_TYPE = 'GET_USERS_BY_TYPE';
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

// Cities
export const GET_CITY_LIST_REQUEST = 'GET_CITY_LIST_REQUEST';
export const GET_CITY_LIST_LOADING = 'GET_CITY_LIST_LOADING';
export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_FAILURE = 'GET_CITY_LIST_FAILURE';
export const CITIES_LOADING = 'CITIES_LOADING';
export const GET_ALL_CITIES = 'GET_ALL_CITIES';
export const GET_CITY = 'GET_CITY';
export const ADD_CITY_REQUEST = 'ADD_CITY_REQUEST';
export const ADD_CITY_SUCCESS = 'ADD_CITY_SUCCESS';
export const ADD_CITY_FAILURE = 'ADD_CITY_FAILURE';
export const UPDATE_CITY_REQUEST = 'UPDATE_CITY_REQUEST';
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';
export const UPDATE_CITY_FAILURE = 'UPDATE_CITY_FAILURE';
export const DELETE_CITY_REQUEST = 'DELETE_CITY_REQUEST';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_FAILURE = 'DELETE_CITY_FAILURE';

// DeliveredBy
export const GET_DELIVERED_BY_LIST_REQUEST = 'GET_DELIVERED_BY_LIST_REQUEST';
export const GET_DELIVERED_BY_LIST_LOADING = 'GET_DELIVERED_BY_LIST_LOADING';
export const GET_DELIVERED_BY_LIST_SUCCESS = 'GET_DELIVERED_BY_LIST_SUCCESS';
export const GET_DELIVERED_BY_LIST_FAILURE = 'GET_DELIVERED_BY_LIST_FAILURE';
export const DELIVERED_BY_LOADING = 'DELIVERED_BY_LOADING';
export const GET_ALL_DELIVERED_BY = 'GET_ALL_DELIVERED_BY';
export const GET_DELIVERED_BY = 'GET_DELIVERED_BY';
export const ADD_DELIVERED_BY_REQUEST = 'ADD_DELIVERED_BY_REQUEST';
export const ADD_DELIVERED_BY_SUCCESS = 'ADD_DELIVERED_BY_SUCCESS';
export const ADD_DELIVERED_BY_FAILURE = 'ADD_DELIVERED_BY_FAILURE';
export const UPDATE_DELIVERED_BY_REQUEST = 'UPDATE_DELIVERED_BY_REQUEST';
export const UPDATE_DELIVERED_BY_SUCCESS = 'UPDATE_DELIVERED_BY_SUCCESS';
export const UPDATE_DELIVERED_BY_FAILURE = 'UPDATE_DELIVERED_BY_FAILURE';
export const DELETE_DELIVERED_BY_REQUEST = 'DELETE_DELIVERED_BY_REQUEST';
export const DELETE_DELIVERED_BY_SUCCESS = 'DELETE_DELIVERED_BY_SUCCESS';
export const DELETE_DELIVERED_BY_FAILURE = 'DELETE_DELIVERED_BY_FAILURE';

// Garage Finish
export const GET_GARAGE_FINISH_LIST_REQUEST = 'GET_GARAGE_FINISH_LIST_REQUEST';
export const GET_GARAGE_FINISH_LIST_LOADING = 'GET_GARAGE_FINISH_LIST_LOADING';
export const GET_GARAGE_FINISH_LIST_SUCCESS = 'GET_GARAGE_FINISH_LIST_SUCCESS';
export const GET_GARAGE_FINISH_LIST_FAILURE = 'GET_GARAGE_FINISH_LIST_FAILURE';
export const GARAGE_FINISHES_LOADING = 'GARAGE_FINISHES_LOADING';
export const GET_ALL_GARAGE_FINISHES = 'GET_ALL_GARAGE_FINISHES';
export const GET_GARAGE_FINISH = 'GET_GARAGE_FINISH';
export const ADD_GARAGE_FINISH_REQUEST = 'ADD_GARAGE_FINISH_REQUEST';
export const ADD_GARAGE_FINISH_SUCCESS = 'ADD_GARAGE_FINISH_SUCCESS';
export const ADD_GARAGE_FINISH_FAILURE = 'ADD_GARAGE_FINISH_FAILURE';
export const UPDATE_GARAGE_FINISH_REQUEST = 'UPDATE_GARAGE_FINISH_REQUEST';
export const UPDATE_GARAGE_FINISH_SUCCESS = 'UPDATE_GARAGE_FINISH_SUCCESS';
export const UPDATE_GARAGE_FINISH_FAILURE = 'UPDATE_GARAGE_FINISH_FAILURE';
export const DELETE_GARAGE_FINISH_REQUEST = 'DELETE_GARAGE_FINISH_REQUEST';
export const DELETE_GARAGE_FINISH_SUCCESS = 'DELETE_GARAGE_FINISH_SUCCESS';
export const DELETE_GARAGE_FINISH_FAILURE = 'DELETE_GARAGE_FINISH_FAILURE';

// Garage Stall
export const GET_GARAGE_STALL_LIST_REQUEST = 'GET_GARAGE_STALL_LIST_REQUEST';
export const GET_GARAGE_STALL_LIST_LOADING = 'GET_GARAGE_STALL_LIST_LOADING';
export const GET_GARAGE_STALL_LIST_SUCCESS = 'GET_GARAGE_STALL_LIST_SUCCESS';
export const GET_GARAGE_STALL_LIST_FAILURE = 'GET_GARAGE_STALL_LIST_FAILURE';
export const GARAGE_STALLS_LOADING = 'GARAGE_STALLS_LOADING';
export const GET_ALL_GARAGE_STALLS = 'GET_ALL_GARAGE_STALLS';
export const GET_GARAGE_STALL = 'GET_GARAGE_STALL';
export const ADD_GARAGE_STALL_REQUEST = 'ADD_GARAGE_STALL_REQUEST';
export const ADD_GARAGE_STALL_SUCCESS = 'ADD_GARAGE_STALL_SUCCESS';
export const ADD_GARAGE_STALL_FAILURE = 'ADD_GARAGE_STALL_FAILURE';
export const UPDATE_GARAGE_STALL_REQUEST = 'UPDATE_GARAGE_STALL_REQUEST';
export const UPDATE_GARAGE_STALL_SUCCESS = 'UPDATE_GARAGE_STALL_SUCCESS';
export const UPDATE_GARAGE_STALL_FAILURE = 'UPDATE_GARAGE_STALL_FAILURE';
export const DELETE_GARAGE_STALL_REQUEST = 'DELETE_GARAGE_STALL_REQUEST';
export const DELETE_GARAGE_STALL_SUCCESS = 'DELETE_GARAGE_STALL_SUCCESS';
export const DELETE_GARAGE_STALL_FAILURE = 'DELETE_GARAGE_STALL_FAILURE';

// House Type
export const GET_HOUSE_TYPE_LIST_REQUEST = 'GET_HOUSE_TYPE_LIST_REQUEST';
export const GET_HOUSE_TYPE_LIST_LOADING = 'GET_HOUSE_TYPE_LIST_LOADING';
export const GET_HOUSE_TYPE_LIST_SUCCESS = 'GET_HOUSE_TYPE_LIST_SUCCESS';
export const GET_HOUSE_TYPE_LIST_FAILURE = 'GET_HOUSE_TYPE_LIST_FAILURE';
export const HOUSE_TYPES_LOADING = 'HOUSE_TYPES_LOADING';
export const GET_ALL_HOUSE_TYPES = 'GET_ALL_HOUSE_TYPES';
export const GET_HOUSE_TYPE = 'GET_HOUSE_TYPE';
export const ADD_HOUSE_TYPE_REQUEST = 'ADD_HOUSE_TYPE_REQUEST';
export const ADD_HOUSE_TYPE_SUCCESS = 'ADD_HOUSE_TYPE_SUCCESS';
export const ADD_HOUSE_TYPE_FAILURE = 'ADD_HOUSE_TYPE_FAILURE';
export const UPDATE_HOUSE_TYPE_REQUEST = 'UPDATE_HOUSE_TYPE_REQUEST';
export const UPDATE_HOUSE_TYPE_SUCCESS = 'UPDATE_HOUSE_TYPE_SUCCESS';
export const UPDATE_HOUSE_TYPE_FAILURE = 'UPDATE_HOUSE_TYPE_FAILURE';
export const DELETE_HOUSE_TYPE_REQUEST = 'DELETE_HOUSE_TYPE_REQUEST';
export const DELETE_HOUSE_TYPE_SUCCESS = 'DELETE_HOUSE_TYPE_SUCCESS';
export const DELETE_HOUSE_TYPE_FAILURE = 'DELETE_HOUSE_TYPE_FAILURE';

// Options
export const GET_OPTION_LIST_REQUEST = 'GET_OPTION_LIST_REQUEST';
export const GET_OPTION_LIST_LOADING = 'GET_OPTION_LIST_LOADING';
export const GET_OPTION_LIST_SUCCESS = 'GET_OPTION_LIST_SUCCESS';
export const GET_OPTION_LIST_FAILURE = 'GET_OPTION_LIST_FAILURE';
export const OPTIONS_LOADING = 'OPTIONS_LOADING';
export const GET_ALL_OPTIONS = 'GET_ALL_OPTIONS';
export const GET_OPTION = 'GET_OPTION';
export const ADD_OPTION_REQUEST = 'ADD_OPTION_REQUEST';
export const ADD_OPTION_SUCCESS = 'ADD_OPTION_SUCCESS';
export const ADD_OPTION_FAILURE = 'ADD_OPTION_FAILURE';
export const UPDATE_OPTION_REQUEST = 'UPDATE_OPTION_REQUEST';
export const UPDATE_OPTION_SUCCESS = 'UPDATE_OPTION_SUCCESS';
export const UPDATE_OPTION_FAILURE = 'UPDATE_OPTION_FAILURE';
export const DELETE_OPTION_REQUEST = 'DELETE_OPTION_REQUEST';
export const DELETE_OPTION_SUCCESS = 'DELETE_OPTION_SUCCESS';
export const DELETE_OPTION_FAILURE = 'DELETE_OPTION_FAILURE';

// User Types
export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_LOADING = 'GET_USER_LIST_LOADING';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';
export const USER_TYPES_LOADING = 'USER_TYPES_LOADING';
export const GET_ALL_USER_TYPES = 'GET_ALL_USER_TYPES';
export const GET_USER_TYPE = 'GET_USER_TYPE';
export const ADD_USER_TYPE = 'ADD_USER_TYPE';
export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE';
export const DELETE_USER_TYPE = 'DELETE_USER_TYPE';

// Vaults
export const GET_VAULT_LIST_REQUEST = 'GET_VAULT_LIST_REQUEST';
export const GET_VAULT_LIST_LOADING = 'GET_VAULT_LIST_LOADING';
export const GET_VAULT_LIST_SUCCESS = 'GET_VAULT_LIST_SUCCESS';
export const GET_VAULT_LIST_FAILURE = 'GET_VAULT_LIST_FAILURE';
export const VAULTS_LOADING = 'VAULTS_LOADING';
export const GET_ALL_VAULTS = 'GET_ALL_VAULTS';
export const GET_VAULT = 'GET_VAULT';
export const ADD_VAULT_REQUEST = 'ADD_VAULT_REQUEST';
export const ADD_VAULT_SUCCESS = 'ADD_VAULT_SUCCESS';
export const ADD_VAULT_FAILURE = 'ADD_VAULT_FAILURE';
export const UPDATE_VAULT_REQUEST = 'UPDATE_VAULT_REQUEST';
export const UPDATE_VAULT_SUCCESS = 'UPDATE_VAULT_SUCCESS';
export const UPDATE_VAULT_FAILURE = 'UPDATE_VAULT_FAILURE';
export const DELETE_VAULT_REQEUST = 'DELETE_VAULT_REQEUST';
export const DELETE_VAULT_SUCCESS = 'DELETE_VAULT_SUCCESS';
export const DELETE_VAULT_FAILURE = 'DELETE_VAULT_FAILURE';

// Rate Plan
export const GET_RATE_PLAN_LIST_REQUEST = 'GET_RATE_PLAN_LIST_REQUEST';
export const GET_RATE_PLAN_LIST_LOADING = 'GET_RATE_PLAN_LIST_LOADING';
export const GET_RATE_PLAN_LIST_SUCCESS = 'GET_RATE_PLAN_LIST_SUCCESS';
export const GET_RATE_PLAN_LIST_FAILURE = 'GET_RATE_PLAN_LIST_FAILURE';
export const RATE_PLAN_LOADING = 'RATE_PLAN_LOADING';
export const GET_ALL_RATE_PLAN = 'GET_ALL_RATE_PLAN';
export const GET_RATE_PLAN = 'GET_RATE_PLAN';
export const ADD_RATE_PLAN = 'ADD_RATE_PLAN';
export const UPDATE_RATE_PLAN = 'UPDATE_RATE_PLAN';
export const DELETE_RATE_PLAN = 'DELETE_RATE_PLAN';

export const LOAD_RATE_PLAN_JOBS = 'LOAD_RATE_PLAN_JOBS';
export const GET_RATE_PLAN_JOB_LEVELS = 'GET_RATE_PLAN_JOB_LEVELS';
export const UPDATE_RATE_PLAN_JOB_LEVELS = 'UPDATE_RATE_PLAN_JOB_LEVELS';

// Rate Plans
export const RATE_PLANS_LOADING = 'RATE_PLANS_LOADING';
export const GET_ALL_RATE_PLANS = 'GET_ALL_RATE_PLANS';
export const GET_RATE_PLANS = 'GET_RATE_PLANS';
export const ADD_RATE_PLANS = 'ADD_RATE_PLANS';
export const UPDATE_RATE_PLANS = 'UPDATE_RATE_PLANS';
export const DELETE_RATE_PLANS = 'DELETE_RATE_PLANS';
export const GET_RATE_PLAN_ITEMS = 'GET_RATE_PLAN_ITEMS';
export const UPDATE_RATE_PLAN_ITEMS = 'UPDATE_RATE_PLAN_ITEMS';

// Rate Plan Jobs
export const RATE_PLAN_JOBS_LOADING = 'RATE_PLAN_JOBS_LOADING';
export const GET_ALL_RATE_PLAN_JOBS = 'GET_ALL_RATE_PLAN_JOBS';
export const GET_RATE_PLAN_JOB = 'GET_RATE_PLAN_JOB';
export const ADD_RATE_PLAN_JOB = 'ADD_RATE_PLAN_JOB';
export const UPDATE_RATE_PLAN_JOB = 'UPDATE_RATE_PLAN_JOB';
export const DELETE_RATE_PLAN_JOB = 'DELETE_RATE_PLAN_JOB';

// Builder
export const GET_BUILDER_LIST_REQUEST = 'GET_BUILDER_LIST_REQUEST';
export const GET_BUILDER_LIST_LOADING = 'GET_BUILDER_LIST_LOADING';
export const GET_BUILDER_LIST_SUCCESS = 'GET_BUILDER_LIST_SUCCESS';
export const GET_BUILDER_LIST_FAILURE = 'GET_BUILDER_LIST_FAILURE';
export const BUILDERS_LOADING = 'BUILDERS_LOADING';
export const GET_ALL_BUILDERS = 'GET_ALL_BUILDERS';
export const GET_BUILDER = 'GET_BUILDER';
export const ADD_BUILDER_REQUEST = 'ADD_BUILDER_REQUEST';
export const ADD_BUILDER_SUCCESS = 'ADD_BUILDER_SUCCESS';
export const ADD_BUILDER_FAILURE = 'ADD_BUILDER_FAILURE';
export const UPDATE_BUILDER_REQUEST = 'UPDATE_BUILDER_REQUEST';
export const UPDATE_BUILDER_SUCCESS = 'UPDATE_BUILDER_SUCCESS';
export const UPDATE_BUILDER_FAILURE = 'UPDATE_BUILDER_FAILURE';
export const DELETE_BUILDER_REQUEST = 'DELETE_BUILDER_REQUEST';
export const DELETE_BUILDER_SUCCESS = 'DELETE_BUILDER_SUCCESS';
export const DELETE_BUILDER_FAILURE = 'DELETE_BUILDER_FAILURE';

// Ceiling Finish
export const GET_CEILING_FINISH_LIST_REQUEST = 'GET_CEILING_FINISH_LIST_REQUEST';
export const GET_CEILING_FINISH_LIST_LOADING = 'GET_CEILING_FINISH_LIST_LOADING';
export const GET_CEILING_FINISH_LIST_SUCCESS = 'GET_CEILING_FINISH_LIST_SUCCESS';
export const GET_CEILING_FINISH_LIST_FAILURE = 'GET_CEILING_FINISH_LIST_FAILURE';
export const CEILING_FINISHES_LOADING = 'CEILING_FINISHES_LOADING';
export const GET_ALL_CEILING_FINISHES = 'GET_ALL_CEILING_FINISHES';
export const GET_CEILING_FINISH = 'GET_CEILING_FINISH';
export const ADD_CEILING_FINISH_REQUEST = 'ADD_CEILING_FINISH_REQUEST';
export const ADD_CEILING_FINISH_SUCCESS = 'ADD_CEILING_FINISH_SUCCESS';
export const ADD_CEILING_FINISH_FAILURE = 'ADD_CEILING_FINISH_FAILURE';
export const UPDATE_CEILING_FINISH_REQUEST = 'UPDATE_CEILING_FINISH_REQUEST';
export const UPDATE_CEILING_FINISH_SUCCESS = 'UPDATE_CEILING_FINISH_SUCCESS';
export const UPDATE_CEILING_FINISH_FAILURE = 'UPDATE_CEILING_FINISH_FAILURE';
export const DELETE_CEILING_FINISH_REQUEST = 'DELETE_CEILING_FINISH_REQUEST';
export const DELETE_CEILING_FINISH_SUCCESS = 'DELETE_CEILING_FINISH_SUCCESS';
export const DELETE_CEILING_FINISH_FAILURE = 'DELETE_CEILING_FINISH_FAILURE';

// Invoice
export const PURCHASE_ORDERS_LOADING = 'PURCHASE_ORDERS_LOADING';
export const GET_ALL_PURCHASE_ORDERS = 'GET_ALL_PURCHASE_ORDERS';
export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER';
export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';
export const DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER';

// Report
export const GET_REPORT_JOB_ORDERS = 'GET_REPORT_JOB_ORDERS';

// JobOrder
export const JOB_ORDERS_LOADING = 'JOB_ORDERS_LOADING';
export const GET_ALL_JOB_ORDERS = 'GET_ALL_JOB_ORDERS';
export const SEARCH_JOB_ORDERS = 'SEARCH_JOB_ORDERS';
export const GET_JOB_ORDER = 'GET_JOB_ORDER';
export const ADD_JOB_ORDER = 'ADD_JOB_ORDER';
export const UPDATE_JOB_ORDER = 'UPDATE_JOB_ORDER';
export const DELETE_JOB_ORDER = 'DELETE_JOB_ORDER';
export const SEND_JOB_ORDER_EMAIL = 'SEND_JOB_ORDER_EMAIL';
export const SEND_INVOICE_EMAIL = 'SEND_INVOICE_EMAIL';
export const SEND_PURCHASE_ORDER_EMAIL = 'SEND_PURCHASE_ORDER_EMAIL';
export const SAVE_FILTER_SEARCH = 'SAVE_FILTER_SEARCH';
export const GET_FILTER_SEARCH = 'GET_FILTER_SEARCH';
export const FILTER_SEARCH_LOADING = 'FILTER_SEARCH_LOADING';

export const GET_HOUSE_LEVEL_STOCK = 'GET_HOUSE_LEVEL_STOCK';
export const UPDATE_HOUSE_LEVEL_STOCK = 'UPDATE_HOUSE_LEVEL_STOCK';

export const UPDATE_JOB_ORDER_USER = 'UPDATE_JOB_ORDER_USER';
export const GET_ALL_GARAGE_JOB_ORDERS = 'GET_ALL_GARAGE_JOB_ORDERS';

// BillingItem
export const GET_BILLING_ITEM_LIST_REQUEST = 'GET_BILLING_ITEM_LIST_REQUEST';
export const GET_BILLING_ITEM_LIST_LOADING = 'GET_BILLING_ITEM_LIST_LOADING';
export const GET_BILLING_ITEM_LIST_SUCCESS = 'GET_BILLING_ITEM_LIST_SUCCESS';
export const GET_BILLING_ITEM_LIST_FAILURE = 'GET_BILLING_ITEM_LIST_FAILURE';
export const BILLING_ITEMS_LOADING = 'BILLING_ITEMS_LOADING';
export const GET_ALL_BILLING_ITEMS = 'GET_ALL_BILLING_ITEMS';
export const GET_BILLING_ITEM = 'GET_BILLING_ITEM';
export const ADD_BILLING_ITEM_REQUEST = 'ADD_BILLING_ITEM_REQUEST';
export const ADD_BILLING_ITEM_SUCCESS = 'ADD_BILLING_ITEM_SUCCESS';
export const ADD_BILLING_ITEM_FAILURE = 'ADD_BILLING_ITEM_FAILURE';
export const UPDATE_BILLING_ITEM_REQUEST = 'UPDATE_BILLING_ITEM_REQUEST';
export const UPDATE_BILLING_ITEM_SUCCESS = 'UPDATE_BILLING_ITEM_SUCCESS';
export const UPDATE_BILLING_ITEM_FAILURE = 'UPDATE_BILLING_ITEM_FAILURE';
export const DELETE_BILLING_ITEM_REQUEST = 'DELETE_BILLING_ITEM_REQUEST';
export const DELETE_BILLING_ITEM_SUCCESS = 'DELETE_BILLING_ITEM_SUCCESS';
export const DELETE_BILLING_ITEM_FAILURE = 'DELETE_BILLING_ITEM_FAILURE';
export const GET_ALL_UNITS = 'GET_ALL_UNITS';

// BillingItemGroup
export const GET_ALL_BILLING_ITEM_GROUPS = 'GET_ALL_BILLING_ITEM_GROUPS';
// HouseLevelType
export const GET_HOUSE_LEVEL_TYPE_LIST_REQUEST = 'GET_HOUSE_LEVEL_TYPE_LIST_REQUEST';
export const GET_HOUSE_LEVEL_TYPE_LIST_LOADING = 'GET_HOUSE_LEVEL_TYPE_LIST_LOADING';
export const GET_HOUSE_LEVEL_TYPE_LIST_SUCCESS = 'GET_HOUSE_LEVEL_TYPE_LIST_SUCCESS';
export const GET_HOUSE_LEVEL_TYPE_LIST_FAILURE = 'GET_HOUSE_LEVEL_TYPE_LIST_FAILURE';
export const HOUSE_LEVEL_TYPES_LOADING = 'HOUSE_LEVEL_TYPES_LOADING';
export const GET_ALL_HOUSE_LEVEL_TYPES = 'GET_ALL_HOUSE_LEVEL_TYPES';
export const GET_HOUSE_LEVEL_TYPE = 'GET_HOUSE_LEVEL_TYPE';
export const ADD_HOUSE_LEVEL_TYPE_REQUEST = 'ADD_HOUSE_LEVEL_TYPE_REQUEST';
export const ADD_HOUSE_LEVEL_TYPE_SUCCESS = 'ADD_HOUSE_LEVEL_TYPE_SUCCESS';
export const ADD_HOUSE_LEVEL_TYPE_FAILURE = 'ADD_HOUSE_LEVEL_TYPE_FAILURE';
export const UPDATE_HOUSE_LEVEL_TYPE_REQUEST = 'UPDATE_HOUSE_LEVEL_TYPE_REQUEST';
export const UPDATE_HOUSE_LEVEL_TYPE_SUCCESS = 'UPDATE_HOUSE_LEVEL_TYPE_SUCCESS';
export const UPDATE_HOUSE_LEVEL_TYPE_FAILURE = 'UPDATE_HOUSE_LEVEL_TYPE_FAILURE';
export const DELETE_HOUSE_LEVEL_TYPE_REQUEST = 'DELETE_HOUSE_LEVEL_TYPE_REQUEST';
export const DELETE_HOUSE_LEVEL_TYPE_SUCCESS = 'DELETE_HOUSE_LEVEL_TYPE_SUCCESS';
export const DELETE_HOUSE_LEVEL_TYPE_FAILURE = 'DELETE_HOUSE_LEVEL_TYPE_FAILURE';
